<template>
	<div id="cartao" class="col-12">
	
		<div id="frente">
			<div class="bloco1">
				<img id="logo" src="../../public/logo_gip.png">
			</div>
			<div class="bloco2">
				<span class="dados"><small>Título:</small><br>{{ currentUser.titlenumber }}</span>
				<span class="dados"><small>Nome:</small><br>{{ currentUser.name }}</span>
				<span class="dados"><small>Vencimento exame:</small><br>{{ userFormatedDate }}</span>
			</div>
			<div class="bloco3">
				<qrcode-vue :value="currentUser.associatenumber" :size="size" level="H" id="qrcode"/>
			</div>
		</div>

	</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import moment from 'moment';

export default {
  name: 'Profile',
  components: {
    QrcodeVue
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
	userFormatedDate() {
      if (!this.$store.state.auth.user.examexpiration) return ''
      return moment(this.$store.state.auth.user.examexpiration).format('DD/MM/YYYY')
    }
  },
  data() {
    return {
      size: 100,
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>

<style>
body {
	background: #a6a6a6;
}

#cartao {
	color: rgb(34, 33, 33);
	font-family: halter;
	cursor: pointer;
}

#frente {
	border-radius: 13px;
	width: 100%;
	/* height: 236px; */
    height: 500px;
	margin: 0 auto;
	margin-top: 50px;
	/* background: #000 url('dark.jpg'); */
    background: rgb(248, 251, 255);
	/* max-width: 375px; */
    max-width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#logo {
	width: 120px;
	height: 120px;
	float: center;
	margin-top: 20px;
}

#qrcode {
	width: 120px;
	height: 120px;
}

#foto {
	width: 150px;
	height: 150px;
	margin-bottom: 20px;
	margin-top: 40px;
	border: 1px solid black;                
}

.dados {
	display: block;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin: 5px 0 0 27px;
}

.validade {
	font-family: arial, sans-serif;
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	display: inline-block;
	margin: 2px 0 0 27px;
}

.data {
	font-size: 13px
}

.bloco1 {
  display: flex;
  justify-content: center;
}

.bloco2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bloco3 {
  display: flex; 
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

</style>